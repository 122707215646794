<header id="mm-header" class="header-non-login">

    <div class="header-wrapper">
        <h1>
            <a href="/mojo/mpcombo">
                <img src="{{ imgUrl }}mm-images/mm-logo.svg" alt="logo" />
                <!-- <img src="{{ imgUrl }}mm-images/mm-logo-azadi.svg" alt="logo" /> -->
            </a>
        </h1>

        <nav class="links">

        </nav>
        <nav class="main">
            <li class="user-login user-login-web">
                <div class="dropdown">
                    <a *ngIf="!isLogin" href="/mojofeed/register?pn={{pn}}" class="px-btn px-4 theme">Login</a>
                    <a *ngIf="isLogin" href="/mojo/logout" class="px-btn px-4 theme">Logout</a>
                </div>
            </li>
        </nav>
    </div>
</header>