import { Injectable,TemplateRef } from '@angular/core';
import { GlobalService } from '@common/global';
@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toasts: any[] = [];
  constructor(private _global: GlobalService) { }
  show(textOrTpl: string | TemplateRef<any>, type:string='success',delay:number= 1500) {
    let types={'success':'success','danger':'danger','info':'info'};
   let op= { classname: 'bg-'+this._global.getVal(types,type,'success')+' text-light', delay: delay };
   this.toasts.push({ textOrTpl, ...op });
  }

  remove(toast:any) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }
}
