import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '@common/global';
import { DomSanitizer } from '@angular/platform-browser';
import { UpperCasePipe } from '@angular/common';
import { environment } from '@env/environment';
@Pipe({
  name: 'common'
})
export class CommonPipe implements PipeTransform {
  constructor(private _global: GlobalService, private sanitizer: DomSanitizer, private _uperCasePipe: UpperCasePipe) { }
  transform(value: any, ...args: unknown[]): any {
    if (args[0] == 'getVal') {
      return this._global.getVal(value, args[1], '');
    } else if (args[0] == 'atarget') {
      return this._global.getAnchorTraget();
    } else if (args[0] == 'stockurl') {
      let stockid_key: any = 'stockid';
      if (typeof args[1] != 'undefined') {
        stockid_key = args[1];
      }
      let exch = this._global.getVal(value, 'exch', 0)
      return environment.STOCK_URL + this._global.stockUrl(value, stockid_key, exch);
    } else if (args[0] == 'tradingstockurl') {
      let stockid_key: any = 'stockid';
      if (typeof args[1] != 'undefined') {
        stockid_key = args[1];
      }
      //news.call_duration=='W'?'M':''

      // "&Exchange=" + exch+ "&Positional=" + positional;
      return environment.TRADING_URL + this._global.stockTradingUrl(value, stockid_key, '0');
    } else if (args[0] == 'mfurl') {
      let schemeid_key: any = 'schemeid';
      if (typeof args[1] != 'undefined') {
        schemeid_key = args[1];
      }
      return environment.MF_URL + this._global.mfUrl(value, schemeid_key);
    } else if (args[0] == 'inlcudes') {
      return this._global.includes(value, args[1]);
    } else if (args[0] == 'userimg') {
      return this._global.userImg(value, args[1]);
    }/* else if (args[0] == 'stockbtncolor') {
      return this._global.getStockBtnColor(value);
    }else if (args[0] == 'stockbtncolortext') {
      return this._global.getStockBtnColorText(value);
    }  */else if (args[0] == 'INR') {
      if (value) {
        return this._global.INRConvert(value, args[1]);
      } else {
        return this._global.INRConvert(0, args[1]);
      }
    } else if (args[0] == 'convertIndRs') {
      if (value) {
        return this._global.convertIndRs(value, args[1]);
      } else {
        return this._global.convertIndRs(0, args[1]);
      }
    } else if (args[0] == 'rmspace') {
      return this._global.removeSpace(value);
    } else if (args[0] == 'mainclass') {
      return 'page-section';
    } else if (args[0] == 'scoreonlypaiduser') {
      let r = false;
      if (args[2] == true) {
        r = true;
      } else {
        let ctype = this._global.getVal(value, args[1]);
        if (this._global.includes(['sell', 'Sell', 'Strong Sell'], ctype)) {
          r = true;
        }
      }
      return r;
    } else if (args[0] == 'findreplace') {
      return this._global.findReplace(value, args[1], args[2]);
    } else if (args[0] == 'trusthtml') {
      if (value)
        return this.sanitizer.bypassSecurityTrustHtml(value);
      else
        return '';

    } else if (args[0] == 'daysuffix') {
      let s = '';
      let d = Number(value);
      if (d > 0) {
        switch (d % 10) {
          case 1:
            return d + 'st';
          case 2:
            return d + 'nd';
          case 3:
            return d + 'rd';
          default:
            return d + 'th';
        }
      }
      return d;
    } else if (args[0] == 'reportshow') {
      let r = false;
      let pchart = value.split("_");
      if (this._uperCasePipe.transform(pchart.at(-1)) == args['1']) {
        r = true;
      }
      return r;
    } else if (args[0] == "innerHtml") {
      if (value != undefined) {
        return this.sanitizer.bypassSecurityTrustHtml(value);
      }

    } else if (args[0] == 'tsort') {
      let cname = '';
      if (this._global.getVal(value, args['1'])) {
        if (this._global.getVal(value, args['1']) == '1') {
          cname = ' <i class="bi sort-up bi-caret-up-fill"></i> ';
        } else {
          cname = '  <i class="bi sort-down bi-caret-down-fill"></i> ';
        }
      } else {
        cname = '  <i class="bi bi-caret-up-fill"></i> <i class="bi bi-caret-down-fill"></i>';
      }
      return '<span class="caret-wrap"> ' + cname + '</span>';
    } else if (args[0] == 'tddir') {
      let cls = '';
      if (args['1']) {
        let obj: any = args['1'];
        if (this._global.getVal(value, obj?.dir)) {
          cls = 'mm-badge-' + (value[obj?.dir] == '1' ? 'success' : 'danger');
          if (obj?.dir_txt == 'text') {
            cls = 'text-' + (value[obj?.dir] == '1' ? 'success' : 'danger');
            if (obj?.key == 'bse_return' && value['bse_return'] == '0.00') {
              cls = 'text-block';
            }
          }
          if (obj?.key == 'perfomance' && value['perfomance'] == '0.00') {
            cls = 'mm-badge-gray';
          }
        }
      }
      return cls;
    } else if (args[0] == 'getDirClrDefault') {
      return this._global.getDirClrDefault(value, args[1]);
    } else if (args[0] == 'getScoreColor') {
      return this._global.getScoreColor(value);
    } else if (args[0] == 'getClassbyClr') {
      return this._global.getClassbyClr(value);
    } else if (args[0] == 'getDotDirArrow') {
      return this._global.getDotDirCls(value);
    } else if (args[0] == 'getDirPfArrow') {
      return this._global.getDirPfArrow(value);
    } else if (args[0] == 'numberWithCommas') {
      return this._global.numberWithCommas(value);
    } else if (args[0] == 'techScoreClr') {
      return this._global.getTechnicalScoreClass(value);
    } else if (args[0] == 'getScoreTextColor') {
      return this._global.getScoreTextColor(value);
    } else if (args[0] == 'getClassbyTechClr') {
      return this._global.getClassbyTechClr(value);
    } else if (args[0] == 'capitalize') {
      if (!value) return value;
      return value.replace(/\b\w/g, (first:any) => first.toLocaleUpperCase());
    } else if (args[0] == 'ordinal') {
      if (!value) return value.toString();
      const suffix = ['th', 'st', 'nd', 'rd'];
      const v = value % 100;
      return value + (suffix[(v - 20) % 10] || suffix[v] || suffix[0]);
    } else {
      return '';
    }

  }

}
