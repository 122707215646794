export const environment = {
  production: true,
  FRAPI_URL: 'https://frapi.marketsmojo.com/',
  MOJO_URL: 'https://www.marketsmojo.com/',
  TRA80_URL: 'https://frapi.trading80.com/',
  TRADING_URL: 'https://www.trading80.com/mojotechnical/',
  STOCK_URL: 'https://www.marketsmojo.com/Stocks',
  MF_URL:     'https://www.marketsmojo.com/Mutualfund/mutualfunds/',
  MOJO_IMG_URL: 'https://www.marketsmojo.com/',
  MOJO_JS_URL: 'https://j.marketsmojo.com/',
  INFINITY_URL:'https://www.mojoinfinity.com/',
  INFINITY_FRAPI_URL: 'https://frapi.mojoinfinity.com/',
  MARKETS_URL:'markets',
  SMALLCASE_GATEWAY: 'marketsmojo',
  ISCLMM:false
};
if (window.location.host == "prepod-www.marketsmojo.com") {

  environment.FRAPI_URL = 'https://prepod-frapi.marketsmojo.com/';
  environment.MOJO_URL = 'https://prepod-www.marketsmojo.com/';
  environment.TRA80_URL = 'https://frapi.trading80.com/';
  environment.STOCK_URL='https://prepod-www.marketsmojo.com/stock_new';
  environment.MOJO_IMG_URL= 'https://prepod-st.marketsmojo.com/images/';
  environment.MOJO_JS_URL= 'https://prepod-st.marketsmojo.com/js/';
  environment.MF_URL=    'https://prepod-www.marketsmojo.com/Mutualfund/mutualfunds/';
  environment.TRADING_URL='https://prepod-www.trading80.com/mojotechnical/';
  environment.INFINITY_URL='https://prepod-www.mojoinfinity.com/';
  environment.INFINITY_FRAPI_URL = 'https://prepod-frapi.mojoinfinity.com/';
  environment.MARKETS_URL='markets';
  environment.SMALLCASE_GATEWAY = 'marketsmojo';
} else if (window.location.host == "www.marketsmojo.com") {
  environment.FRAPI_URL = 'https://frapi.marketsmojo.com/';
  environment.MOJO_URL = 'https://www.marketsmojo.com/';
  environment.TRA80_URL = 'https://frapi.trading80.com/';
  environment.STOCK_URL= 'https://www.marketsmojo.com/Stocks';
  environment.MOJO_IMG_URL= 'https://i.marketsmojo.com/';
  environment.MOJO_JS_URL= 'https://j.marketsmojo.com/';
  environment.MF_URL=    'https://www.marketsmojo.com/Mutualfund/mutualfunds/';
  environment.INFINITY_URL='https://www.mojoinfinity.com/';
  environment.INFINITY_FRAPI_URL = 'https://frapi.mojoinfinity.com/'
  environment.TRADING_URL='https://www.trading80.com/mojotechnical/';
  environment.MARKETS_URL='markets';
  environment.SMALLCASE_GATEWAY = 'marketsmojo';
} else if (window.location.host == "sandbox-www.marketsmojo.com") {
  environment.FRAPI_URL = 'https://sandbox-frapi.marketsmojo.com/';
  environment.MOJO_URL = 'https://sandbox-www.marketsmojo.com/';
  environment.TRA80_URL = 'https://frapi.trading80.com/';
  environment.INFINITY_URL='https://sandbox.mojoinfinity.com/';
  environment.INFINITY_FRAPI_URL ='https://sandbox-frapi.mojoinfinity.com/';
  environment.STOCK_URL= 'https://sandbox-www.marketsmojo.com/stocks_new';
  environment.MOJO_IMG_URL= 'https://sandbox-st.marketsmojo.com/images/';
  environment.MOJO_JS_URL= 'https://sandbox-st.marketsmojo.com/js/';
  environment.MF_URL=    'https://sandbox-www.marketsmojo.com/Mutualfund/mutualfunds/';
  environment.TRADING_URL='https://sandbox.mojotechnicals.com/mojotechnical/';
  environment.MARKETS_URL='markets_new';
  environment.SMALLCASE_GATEWAY = 'marketsmojo-dev';
}
