import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LowerCasePipe,DatePipe ,DecimalPipe,UpperCasePipe,formatDate} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SwiperModule } from 'swiper/angular';
import { NgApexchartsModule } from "ng-apexcharts";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { GlobalService } from './_common/global';
import { ApiService } from './_services/api.service';
import { pServices } from './_common/pservices';
import { UserService } from './_services/user.service';
import { UsertrackService } from './_services/usertrack.service';
import { CommonPipe } from './_pipes/common.pipe';
import { SafePipe } from './_pipes/safe.pipe';
import { FooterComponent } from './footer/footer.component';
import { CookieService } from 'ngx-cookie-service';
import { StockScorePercentageComponent } from './common/stock-score-percentage/stock-score-percentage.component';
import { LinechartComponent } from './common/linechart/linechart.component';
import { PageloaderComponent } from './pageloader/pageloader.component';
import { LoaderComponent } from './common/loader/loader.component';
import { PremiumPackSectionComponent } from './common/premium-pack-section/premium-pack-section.component';
import { CommonPageModule } from './common/commonPage.module';
import { PnpageComponent } from './common/pnpage/pnpage.component';
import { HeaderComponent } from './common/header/header.component';
import { PipeModule } from '@app/_pipes/pipe.module';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastComponent } from './common/toast/toast.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    ToastComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SwiperModule,
    FormsModule,
    NgbToastModule,
    BrowserAnimationsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    HttpClientModule,
    NgbModule,
    PipeModule,
  ],
  exports:[],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [GlobalService,ApiService,pServices,UserService,UsertrackService,SafePipe,CookieService,LowerCasePipe,DatePipe ,DecimalPipe,UpperCasePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
