import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '@app/_common/global';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  logo_img:any='';
  imgUrl:any='';
  isPaid:boolean=false;
  isLogin:boolean=false;
  userDetails:any=[];
  usubscription:any;
  pn: any = '';
  constructor(private _global: GlobalService, private _actRoute: ActivatedRoute) { 
    this.imgUrl = this._global.IMG_URL;
 
    this.usubscription = setInterval(() => {
      let ums_elem: any = document.getElementById('user_login_msg');
      let umsg: any = ums_elem.value;
      if (umsg != "[]" && umsg != "") {
        clearInterval(this.usubscription);
        this._global.LOGIN_USER_DETAIL = JSON.parse(umsg);
        this.userDetails =this._global.LOGIN_USER_DETAIL;
        let login_response = this._global.getVal(this._global.LOGIN_USER_DETAIL, "data");
        this.isPaid = this._global.getVal(login_response, "actual_paid", false);
        this.isLogin = this._global.getVal(login_response, "is_login");
      } 
    }, 300);
    this._actRoute.queryParams.subscribe((params: any) => {
      this.pn=this._global.getVal(params, "pn");
    });

  }

}
