import { Injectable } from '@angular/core';
import { GlobalService } from '@common/global';
import { ApiService } from '@services/api.service';
import { environment } from '@env/environment';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _global: GlobalService, private api: ApiService) { }
  checklogin(obj: any) {
    return this.api.getCall(environment.MOJO_URL + 'mojo/checklogin/0');
  }
  getCardData(request: any) {
    return this.api.getCall(environment.FRAPI_URL + 'Usermessaging/userMessagingData?page=' + request.page);
  }
  getUserNotification(offset: any=0) {
    return this.api.getCall(environment.FRAPI_URL + 'notification/getUserNotification?offset=' + offset);
  }
  checkUserExist(obj: any = {}) {
    return this.api.call(environment.FRAPI_URL + 'premium/checkUserExist', obj);
  }
  easySignup(obj: any = {}) {
    return this.api.call(environment.MOJO_URL + 'mojo/easysignup?s=nc', obj, 'json');
  }
  authenticate(obj: any) {
    return this.api.call(environment.MOJO_URL + 'mojo/authenticate/0', obj, 'json');
  }
  resetPassword(obj: any) {
    return this.api.call(environment.MOJO_URL + 'mojo/resetpassword', obj, 'json');
  }
  generateEmailOtp(obj: any = {}, resend: number) {
    return this.api.call(environment.FRAPI_URL + `User_Otpverify/generateOtp/email/${resend}`, obj);
  }
  verifyOtp(obj: any = {}) {
    return this.api.call(environment.FRAPI_URL + 'User_Otpverify/verifyOtp', obj);
  }
}
