import { Component, HostBinding,OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { GlobalService } from "@app/_common/global";
import { UsertrackService } from "@services/usertrack.service";
import { UserService } from "@services/user.service";
import { filter, map } from "rxjs";
import { pServices } from "@common/pservices";
import { environment } from "@env/environment.prod";
declare const window: any;
import clevertap from 'clevertap-web-sdk';
import {CookieService} from 'ngx-cookie-service';
import { ToastService } from "@services/toast.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  device_type = "";
  page: any = "";
  header_load = false;
  isLogin = false;
  login_response: any = [];
  apaid: boolean = false;
  active: any = 1;
  card_data: any = [];
  device_app_ver = "";
  device: any = "";
  header_show: boolean = true;
  show_header: any = "";
  footer_show: boolean = true;
  packageDetails: any = {};
  usubscription:any;
  topNewMsgInterval: any;
  topNewMsg: any = [];
  isTopNewsMsgLoaded: boolean = false;
  /*   , */
  userMsg:any=[];
  userLoginMsg:any=[];
  utm_source:any='';
  is_clmm: boolean = false;
  CLMM_LIST:any=['integrated'];
  @HostBinding('class.clmm-theme') get addClmmTheme() {
    return this.is_clmm;
  }
  constructor(private _cookie: CookieService,
    public pservice: pServices,
    private usertrack: UsertrackService,
    private router: Router,
    private _users: UserService,
    private _global: GlobalService,
    private _toast: ToastService
  ) {

    let cookie=this._cookie.get('__CLMM');
    if(cookie && this.CLMM_LIST.includes(cookie)) {
      this.is_clmm=true;
      environment.ISCLMM=true;
    }

  //  this.is_clmm= environment.ISCLMM;
    this.device_type = this._global.getDevicep();
    this.device_app_ver = this._global.getAppVersion();
    this.device = this._global.getAppType();
    this._global.DEVICETYPE = this.device_type;
    this._global.DEVICEAPPVER = this.device_app_ver;
    this._global.DEVICE = this.device;
    this.userMsg=JSON.stringify(this.userMsg);
    let nhit = 0;
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;

          let routeTitle = "";
          let header = false;
          let footer = false;
          this.utm_source=route.snapshot.queryParamMap.get('utm_source');
         
          if(this.utm_source!='' && this.utm_source!=null){
            var date = new Date();
            this._cookie.set("utm_source_track", this.utm_source,date.getTime() +604800,'/','.marketsmojo.com');
            let utm_first_src=this._cookie.get('utm_first_src');
              if(utm_first_src==''){
                this._cookie.set("utm_first_src", this.utm_source,date.getTime() +604800,'/','.marketsmojo.com');
              }
          } 
          while (route!.firstChild) {
            route = route.firstChild;
          }

          if (route.snapshot.data["title"]) {
            routeTitle = route!.snapshot.data["title"];
          }
          if (
            route.snapshot.data["header"] == undefined ||
            route.snapshot.data["header"] == undefined
          ) {
            header = true;
            this.show_header = "show";
          } else {
            header = false;
            this.show_header = "nop";
          }
          if (
            route.snapshot.data["footer"] == undefined ||
            route.snapshot.data["footer"] == undefined
          ) {
            footer = true;
            this.footer_show = true;
          } else {
            footer = false;
            this.footer_show = false;
          }

          return {
            title: routeTitle,
            header_show: header,
            footer_show: footer,
          };
        }),
      )
      .subscribe((obj: any) => {
        if(!this.is_clmm){
          if (!['simplify-investing','marketingPage','christmasMarketingPage','marketfreeTrialCongratulations'].includes(obj.title)) {
            clevertap.init('848-W99-886Z', 'in1')
            clevertap.privacy.push({useIP: true}); 
            clevertap.event.push("Page View", {
              'PageUrl': window.location.href
            });
          }
        }
        this.header_show = obj.header;

        if (obj.title) {
          this.page = obj.title;
          this._global.PAGE_TITLE = this.page;
        } 
        if (nhit == 0) {
          this.loadUserLogins(this.page);
          nhit++;
        }
      });
    //this.loadUserLogins(this.page);
  }

  loadUserLogins(page: any = "") {

    this._users.checklogin({}).subscribe(
      (data) => {
        //this.header_load = true;
        if (this._global.getVal(data, "code") == "200") {

          this.userLoginMsg=JSON.stringify(data);
          this._global.LOGIN_USER_DETAIL = data;
          this.isLogin = this._global.getVal(data, "data.is_login");
          this.login_response = this._global.getVal(data, "data");
          this.apaid = this._global.getVal(  this.login_response, "actual_paid", false);
          // if( this.page=='mflanding')
          if (this.isLogin) {
            this.header_load = true;
           /*  if ((this.page == 'result_corner_landing' || this.page == 'mojostock_new_landing') && !this.apaid) {
              window.location.href = environment.MOJO_URL + 'mojofeed';
            } */
            let i = 0;
            this.usubscription = setInterval(() => {
              let ums_elem: any = document.getElementById('user_welcome_msg');
              let umsg: any = ums_elem.value;
              if (umsg != "[]") {
                clearInterval(this.usubscription);

                this.card_data = JSON.parse(umsg)
                this._global.LOGIN_USER_CARD_DETAIL = this.card_data;
                this.packageDetails = {};
                if (this.card_data.isdisclaimer == 0) {
                  window.location.href = '/mojo/disclaimernew';
                }
              } else {
                i += 1;
                if (i == 4) {
                  clearInterval(this.usubscription);
                  i = 0;
                }
              }
            }, 1000);
            if (this.page == 'strategy') {
              let j = 0;
              this.topNewMsgInterval = setInterval(() => {
                let top_news_elem: any = document.getElementById('top_news_msg');
                let newsMsg: any = top_news_elem.value;
                if (newsMsg != "[]") {
                  clearInterval(this.topNewMsgInterval);
                  this.isTopNewsMsgLoaded = true;
                  this.topNewMsg = JSON.parse(newsMsg);
                } else {
                  j += 1;
                  if (j == 4) {
                    clearInterval(this.topNewMsgInterval);
                    this.isTopNewsMsgLoaded = true;
                    j = 0;
                  }
                }
              }, 1000);
            }

          } else {
           
            this._global.LOGOUT_DETAIL = this._global.getVal(data, "data");
            if (!['simplify-investing', 'marketingPage', 'mojostock_new_landing', 'result_corner_landing', 'mojostock_new_landing', 'christmasMarketingPage', 'lead'].includes(this.page)) {
              let url = 'mojofeed/login';
              if (this.page == 'strategy') {
               let strategy_type: any = {  1: 'turnaround', 2: 'momemtumnow', 3: 'reliable' };
 
                let u = window.location.href.split("/")
                url = 'mojo/sectionlanding?stn=' +this._global.getVal(strategy_type, u.slice(-1)[0]);
              }
              window.location.href = environment.MOJO_URL + url;
            }else{
              this.header_load = true;
            }

          }
        } else {
          this.header_load = true;
          this._global.LOGOUT_DETAIL = this._global.getVal(data, "data");
          if (!['marketingPage', 'mojostock_new_landing', 'christmasMarketingPage', 'lead'].includes(this.page)) {
            window.location.href = environment.MOJO_URL + 'mojofeed/login';
          }
        }
        if(window.location.pathname=="/mojopro/mojopro-market"){
        this._users.getCardData({ page: this.page }).subscribe((resp: any) => {
          if (this._global.getVal(resp, 'code') == '200') {
            let card:any = this._global.getVal(resp, 'data');
            this.userMsg=JSON.stringify(card)
          }
        });
      }
      },
      (error) => {
        console.log("Validate Error", error);
      },
    );
  }

  onActive() {
    setTimeout(function () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      /*  window.scrollTo(0, 0); */
    }, 150);
  }
  ngOnInit() {
  }
  ngAfterViewInit() {
    var version = Math.floor(Math.random() * 1100000000);
    let node = document.createElement('script');
    node.src = '//www.adplugg.com/serve/js/ad.js?id=' + version;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
