import { Injectable } from '@angular/core';
import { CommonService } from '@services/common.service';
import { UAParser } from "ua-parser-js";
import { GlobalService } from '@common/global';
import { CookieService } from 'ngx-cookie-service';
import { Md5 } from 'ts-md5';
@Injectable({
	providedIn: 'root'
})
export class UsertrackService {

	constructor(private _cookie: CookieService, private _global: GlobalService, private _commons: CommonService) { }
	usertrack(obj: any = {}) {
		//let md5 = new Md5();
		let parser = new UAParser();
		let presult = parser.getResult();
		let m_client_info = '';
		let pageuniqueid = document.location.href + '' + this._cookie.get('_ga') + '' + performance.now();
		let Unique_tracker_pageid = Md5.hashStr(pageuniqueid);
		obj["referrer"] = document.referrer
		obj["location"] = document.location.href
		m_client_info = m_client_info + "\nOS: " + presult["os"]["name"] + " (" + presult["os"]["version"] + ")"
		obj["os_name"] = presult["os"]["name"]
		obj["os_ver"] = presult["os"]["version"]
		m_client_info = m_client_info + "\nPlatform: " + navigator.platform
		obj["platform"] = navigator.platform
		let m_cores = 0;
		if (navigator.hardwareConcurrency)
			m_cores = navigator.hardwareConcurrency

		m_client_info = m_client_info + "\nCPU Cores: " + navigator.hardwareConcurrency
		obj["cores"] = m_cores
		/* if(navigator.cpuClass)
			m_cpu_class = navigator.cpuClass
		else */
		let m_cpu_class = ""
		m_client_info = m_client_info + "\nCPU Class: " + m_cpu_class
		obj["cpu_class"] = m_cpu_class
		m_client_info = m_client_info + "\nCPU: " + presult["cpu"]["architecture"]
		obj["cpu_arch"] = presult["cpu"]["architecture"]
		m_client_info = m_client_info + "\nMemory: ''";//+ navigator.deviceMemory
		/* if(navigator.deviceMemory)
		obj["memory"] = navigator.deviceMemory
		else */
		obj["memory"] = "";
		m_client_info = m_client_info + "\nDevice: " + presult["device"]["vendor"] + ", " + presult["device"]["model"] + ", " + presult["device"]["type"]
		if (presult["device"]["vendor"])
			obj["device_vendor"] = presult["device"]["vendor"]
		else
			obj["device_vendor"] = ""
		if (presult["device"]["model"])
			obj["device_model"] = presult["device"]["model"]
		else
			obj["device_model"] = ""
		if (presult["device"]["type"])
			obj["device_type"] = presult["device"]["type"]
		else
			obj["device_type"] = "";
		/* if(navigator.connection){
		m_client_info = m_client_info + "\nConnection: " + navigator.connection["effectiveType"] 
		obj["connection"] = navigator.connection["effectiveType"] 
		} */
		let offset = new Date().getTimezoneOffset();
		obj["connection"] = '';
		m_client_info = m_client_info + "\nLanguage: " + navigator.language
		obj["lang"] = navigator.language
		m_client_info = m_client_info + "\nScreen Width: " + screen.width
		obj["screen_width"] = screen.width
		m_client_info = m_client_info + "\nScreen Height: " + screen.height
		obj["screen_height"] = screen.height
		m_client_info = m_client_info + "\nColor Depth: " + screen.colorDepth
		obj["screen_color"] = screen.colorDepth
		m_client_info = m_client_info + "\nBrowser: " + presult["browser"]["name"] + " (" + presult["browser"]["version"] + ")"
		obj["browser_name"] = presult["browser"]["name"]
		obj["browser_ver"] = presult["browser"]["version"]
		m_client_info = m_client_info + "\nUser Agent: " + navigator.userAgent
		m_client_info = m_client_info + "\nTime Offset: " + String(offset)
		obj["time_offset"] = offset
		m_client_info = m_client_info + "\nTimezone: " + Intl.DateTimeFormat().resolvedOptions().timeZone
		if (Intl.DateTimeFormat().resolvedOptions().timeZone)
			obj["time_zone"] = Intl.DateTimeFormat().resolvedOptions().timeZone
		else
			obj["time_zone"] = ""

		var agent = (navigator.userAgent).toLowerCase()
		if (agent.indexOf("mm-app") > -1) {
			obj["agent"] = 'app'
		} else if (agent.indexOf("mobile") > -1) {
			obj["agent"] = 'mobile'
		} else {
			obj["agent"] = 'desktop'
		}
		obj['m_id'] = Unique_tracker_pageid;


		this._commons.usertrackingdata(obj).subscribe((data: any) => {
		});
	}
}
