import { Component, Inject, Input, OnInit,ViewChild } from '@angular/core';
import { StockService } from '@services/stock.service';
import { DOCUMENT } from '@angular/common';
import { GlobalService } from '@common/global';
import SwiperCore, { SwiperOptions,Autoplay,Navigation } from 'swiper';
SwiperCore.use([Autoplay,Navigation]);
declare const window: any;
import { environment } from "@env/environment.prod";
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() footer_show:any;
  @Input() isLogin:any;
  @Input() deviceType:any;
  frontend_url: any = [];
  error_msg: string='';
  success_msg: string='';
  subscribe_data: any = [];
  email: any;
  title = 'app';
  client: string;
  img_url: any;
  cyear:any;
  slider_image: any = [];
stock_list=["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z","Other"];
  config: SwiperOptions = {
    slidesPerView: 3,
    spaceBetween: 50,
    navigation: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
  };
  // constructor(@Inject(DOCUMENT) private document: any) { }
  is_clmm: boolean = false;
  constructor(private stockservice: StockService, private _global: GlobalService) {
    this.is_clmm= environment.ISCLMM;
  this.cyear  = new Date().getFullYear();
    this.client = this._global.CLIENT;
    this.frontend_url = this._global.FRONTEND_URL;
    this.img_url = this._global.IMG_URL;

    this.slider_image = [
			// {img_path: "logo/nse-logo.png",title:""},
      {img_path: "logo/axis-securities-logo.png", title: ""},
      {img_path: "logo/smc-logo.png", title: ""},
    /* {img_path: "logo/bse-logo.png",title:""}, */
    // {img_path: "logo/motilal-logo.png",title:""},
    {img_path: "logo/gj-logo.png",title:""},    
    {img_path: "logo/angel-logo-v1.png",title:""},
  /*   {img_path: "logo/choice-logo.png",title:""}, */
    {img_path: "logo/Integrated_footer.png",title:""}
    ];
   /*  var script=['https://sandbox-st.marketsmojo.com/js/UniquePage.js?v=202109153','https://sandbox-st.marketsmojo.com/js/ua_parser.js?v=202109153'];
    script.forEach((value:any, key:any) => {
      var script = document.createElement('script')
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', value);
      document.getElementsByTagName('head')[0].appendChild(script);
    }); */
  }

/*   ngAfterViewInit(){
    setTimeout(() => {
      console.log('afterchnahgeggegegt')
      let node = document.createElement('script');
      node.src = '//www.adplugg.com/serve/js/ad.js';
      node.type = 'text/javascript';
      node.async = true;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
  
      window.AdPlugg = AdPlugg = window.AdPlugg || [];
      var AdPlugg = window.AdPlugg;
      if (AdPlugg) {
        // Initialize the AdPlugg SDK with our AdPlugg Access Code.
        AdPlugg.push({ 'command': 'init', 'accessCode': 'A48221237' });
        AdPlugg.push({ 'command': 'run' });
        
      }
    },3000);
  } */
  ngOnInit() {
  

  }
  
  subscribe(email='') {
    const validEmailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//    console.log("footer", email);
    if (email == undefined) {
      this.error_msg = 'Hey! Please provide your Email id';
    } else if (validEmailRegEx.test(email)) {
//      console.log("subscribe");
      this.stockservice.usersubscribe(email).subscribe((data: any) => {
        if (data.code == 200) {
          // this.subscribe_data = data.data;
          // console.log(this.subscribe_data);
          if (data.data.error_flag == 0) {
            (<any>window).ga('send','event','email capture','submit');

            (<any>window).gtag('event', 'conversion', {'send_to': 'AW-650313021/tZPpCJ701NwBEL36i7YC'});

            this.error_msg = '';
            this.success_msg = data.data.msg;
          } else {
            this.error_msg = data.data.msg;
            this.success_msg = '';
          }
        }
      });
    } else {
      this.error_msg = 'Please enter a valid Email ID';
      // this.error_msg = '';
      // this.success_msg = 'Thank you for subscribing to MarketsMojo';
    }
  }
  

  
  
}
