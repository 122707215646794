/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
/* import clevertap from 'clevertap-web-sdk';
clevertap.init('848-W99-886Z','in1');  */
function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
  return null;
}
let cookies = getCookie('__CLMM');
let  CLMM_LIST:any=['integrated'];
if(cookies && CLMM_LIST.includes(cookies)) {
  environment.ISCLMM=true;
}else{
  // import('clevertap-web-sdk').then(clevertap => {
  //   clevertap.default.init('848-W99-886Z', 'in1');
  // }).catch(error => {
  //   console.error('Failed to import Clevertap:', error);
  // });
}
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
