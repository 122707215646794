import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', data: { title: 'mfeedNotFound' }, loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },
  { path: 'freetrial-congratulations', data: { title: 'marketfreeTrialCongratulations' }, loadChildren: () => import('./congratulations/congratulations.module').then(m => m.CongratulationsModule) },
  { path: 'mojopro-market', data: { header:false,title: 'marketingPage' }, loadChildren: () => import('./marketing-page/marketing-page.module').then(m => m.MarketingPageModule) },
  { path: 'christmas-2023', data: { header: false, title: 'christmasMarketingPage' }, loadChildren: () => import('./christmas-page/christmas-page.module').then(m => m.ChristmasPageModule) },
  { path: 'mojostock-landing', data: { title: 'mojostock_new_landing' }, loadChildren: () => import('./mojostock-landing/mojostock-landing.module').then(m => m.MojostockLandingModule) },
  { path: 'mojo-block', data: { title: 'mojostock_new_landing' }, loadChildren: () => import('./mojo-block/mojo-block.module').then(m => m.MojoBlockModule) },
  { path: 'result-corner-landing', data: {title: 'result_corner_landing'},loadChildren: () => import('./result-corner-landing/result-corner-landing.module').then(m => m.ResultCornerLandingModule) },
  { path: 'strategy/:id', data: { title: 'strategy'} ,loadChildren: () => import('./strategy/strategy.module').then(m => m.StrategyModule) },
  { path: 'gift', data: { title: 'gift'}, loadChildren: () => import('./gift/gift.module').then(m => m.GiftModule) },
  { path: 'simplify-investing', data: { title: 'simplify-investing' }, loadChildren: () => import('./marketing-page/marketing-page.module').then(m => m.MarketingPageModule) },
  { path: 'mojostock', data: { title: 'mojostock_new'}, loadChildren: () => import('./mojostock/mojostock.module').then(m => m.MojostockModule) },
  { path: 'lead',  data: { title: 'lead' },loadChildren: () => import('./lead/lead.module').then(m => m.LeadModule) },
  { path: 'mojoportfolio', data: { title: 'MojoPortfolio'}, loadChildren: () => import('./one-view/one-view.module').then(m => m.OneViewModule)},
  { path: 'delete-account', data: { title: 'delete_account_page'}, loadChildren: () => import('./delete-account/delete-account.module').then(m => m.DeleteAccountModule)},
  { path: 'stock-of-the-week', data: { title: 'stock-of-the-week' }, loadChildren: () => import('./stock-strategy/stock-strategy.module').then(m => m.StockStrategyModule) },
  { path: 'stock-of-the-month', data: { title: 'stock-of-the-month' }, loadChildren: () => import('./stock-strategy/stock-strategy.module').then(m => m.StockStrategyModule) },
  { path: 'mojoVerification/:for',data: { title: 'mojoVerification'}, loadChildren: () => import('./verfication/verification.module').then(m => m.VerificationModule) },
  { path: 'tshirt',data: { title: 'mojoVerification'},  loadChildren: () => import('./tshirt/tshirt.module').then(m => m.TshirtModule) },
  { path: '**', data: { title: 'mfeedNotFound' }, loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
