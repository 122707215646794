import { Injectable, TemplateRef } from '@angular/core'
import { StockService } from '@services/stock.service';
import { GlobalService } from '@common/global';
@Injectable()
export class pServices {
  public cart_count = 0;
  public USER_BANK_DEATILS:any={};
  hptab:any=1;
  constructor(private _global: GlobalService, private stockservice: StockService,) {
     
    this.cartCount();
  }

 public cartCount(){
 
 }
   
 
}
